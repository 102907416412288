/* https://mycolor.space/gradient3?ori=to+right+bottom&hex=%23002D62&hex2=%23D5E4F1&hex3=%23002D62&submit=submit */

html {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16px;
}

body {
  background-image: linear-gradient(
    to right bottom,
    #002d62,
    #2d4c7c,
    #516d97,
    #7590b1,
    #9cb3cc,
    #9cb3cc,
    #9cb3cc,
    #9cb3cc,
    #7590b1,
    #516d97,
    #2d4c7c,
    #002d62
  );
  min-height: 100vh;
}

header,
nav,
section,
footer {
  background-color: #fff;
}

section {
  padding: 3%;
}

footer {
  padding: 1% 3%;
}

.form-check-inline input[type="radio"] {
  display: hidden;
}

.form-check-inline label {
  display: inline-block;
  padding: 5px 8px;
  text-transform: uppercase;
  border: 1px solid #002d62;
  border-radius: 3px;
  color: #002d62;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #002d62;
}

input[type="radio"]:checked + label {
  background-color: #002d62;
  color: #fff;
}

#seminarItems > a:link {
  text-decoration: none !important;
}

#seminarItems a {
  color: #000 !important;
}

.seminarEntry:hover {
  background-color: #d5e4f1;
  cursor: pointer;
}
